var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"adminLeaveManagerWrapper"},[_c('v-row',{staticClass:"px-6 leaves-search-bar",attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"8","md":"6"}},[_c('Search',{attrs:{"placeholder":_vm.placeholder,"isAdvanceSearch":true,"filterConfig":_vm.selectedSearchConfig},on:{"queryString":_vm.queryString,"filterObject":_vm.advanceSearch}})],1)],1),(_vm.totalCount === 0)?_c('div',{staticClass:"content-error-message"},[_vm._v(" No leaves Entry yet. ")]):_vm._e(),_c('div',{staticClass:"info-table"},[_c('v-data-table',{attrs:{"items-per-page":_vm.pageSize,"hide-default-footer":"","headers":_vm.headers,"expanded":_vm.expanded,"show-expand":"","items":_vm.leavesList,"item-key":"_id","fixed-header":true},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.serial_number",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.serial_number))]}},{key:"item.doa",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFormattedDate(item.doa, "MMMM Do YYYY dddd"))+" ")]}},{key:"item.date_from",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFormattedDate(item.date_from, "MMMM Do YYYY dddd"))+" ")]}},{key:"item.date_to",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFormattedDate(item.date_to, "MMMM Do YYYY dddd"))+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"expandable-section table-expanded-background",attrs:{"colspan":headers.length}},[_c('div',{staticClass:"expandable-section-title"},[_vm._v(" Purpose Of Leave ")]),_c('div',{staticClass:"expandable-section-content"},[_vm._v(" "+_vm._s(item.purpose_of_leave)+" ")])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.status != 'REJECTED')?[_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[(item.status != 'ACCEPTED')?_c('v-list-item',{on:{"click":function($event){return _vm.acceptAction(item)}}},[_vm._v("Accept")]):_vm._e(),_c('v-list-item',{on:{"click":function($event){return _vm.rejectAction(item)}}},[_vm._v("Reject")])],1)],1)]:_vm._e()]}}],null,true)})],1),(_vm.isPaginationRequired)?_c('div',{staticClass:"paginationWrapper text-center"},[_c('v-pagination',{staticClass:"pagination-component",attrs:{"length":Math.ceil(_vm.fetchCount / _vm.pageSize),"total-visible":_vm.paginationTotalVisible},on:{"input":_vm.updatedPageNo},model:{value:(_vm.pageNo),callback:function ($$v) {_vm.pageNo=$$v},expression:"pageNo"}}),_c('div',{staticClass:"page-size-dropdown"},[_c('v-autocomplete',{attrs:{"items":_vm.pageSizeList,"auto-select-first":"","solo":"","dense":""},model:{value:(_vm.pageSize),callback:function ($$v) {_vm.pageSize=$$v},expression:"pageSize"}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }